import React from "react";
import Layout from "../../components/lb/layout-maintenance";

const IndexPage = () => {

	var maintenanceSeo = { metaTitle:'An Maintenance | Nocturnal E-Sports', metaDescription:'Startsait vun Dapit, welch an moment enner Konctruction ass.'};

	return (
		<Layout pageen="/" pagelb="/" seo={maintenanceSeo}>
			<section id="banner">
				<div className="inner">
					<h2 className="mt-5">An Maintenance</h2>
					<p>Mir schaffen a moment un engem Update, kommt méi speit zréck w.e.g.</p>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
